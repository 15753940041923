<template>
    <vs-popup
        :title="title"
        :active.sync="show"
        class="vs-con-loading__container"
        id="loading-container_referral_modal"
    >
        <vs-input
            type="text"
            v-model="form.client_name"
            label="Client Name"
            class="mb-3 w-full"
            :danger="errors.client_name"
            :danger-text="errors.client_name"
        />
        <vs-input
            type="text"
            v-model="form.client_phone"
            class="mb-3 w-full"
            label="Client Phone"
            :danger="errors.client_phone"
            :danger-text="errors.client_phone"
        />
        <div class="vx-col w-full mb-3">
            <label class="vs-input--label">Products</label>
            <v-select
                multiple
                taggable
                v-model="form.selected_products"
                :options="productOptions"
                placeholder="Select product(s)"
                class="style-chooser"
            ></v-select>
        </div>

        <vs-input
            type="email"
            v-model="form.client_email"
            label="Client Email"
            class="mb-3 w-full"
            :danger="errors.client_email"
            :danger-text="errors.client_email"
        />

        <vs-textarea v-model="form.client_notes" />

        <vs-divider />
        <div class="flex justify-end">
            <vs-button
                :disabled="!validForm"
                v-on:click="submit"
                color="primary"
                class="mr-3"
                >{{ buttonText }}
            </vs-button>
            <vs-button v-on:click="$emit('close')" type="flat"
                >Cancel</vs-button
            >
        </div>
    </vs-popup>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { http } from '@/services'

export default {
    name: 'EditReferModal',
    mixins: [],
    components: {
        vSelect,
    },
    props: ['showData', 'logData'],
    watch: {
        logData: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.form.id = value.id
                    this.form.client_name = value.client_name
                    this.form.client_email = value.client_email
                    this.form.client_phone = value.client_phone
                    this.form.client_notes = value.client_notes

                    if (value.complex_will)
                        this.form.selected_products.push({
                            label: 'Complex Will',
                            code: 'complex_will',
                        })
                    if (value.power_of_attorney)
                        this.form.selected_products.push({
                            label: 'Lasting Power of Attorney',
                            code: 'power_of_attorney',
                        })

                    if (value.protective_property_trust)
                        this.form.selected_products.push({
                            label: 'Protective Property Trust',
                            code: 'protective_property_trust',
                        })

                    if (value.protect_home)
                        this.form.selected_products.push({
                            label: 'Home Protection Trust',
                            code: 'protect_home',
                        })

                    if (value.right_of_occupation)
                        this.form.selected_products.push({
                            label: 'Right of Occupation',
                            code: 'right_of_occupation',
                        })
                    if (value.life_interest_trust)
                        this.form.selected_products.push({
                            label: 'Life Interest Trust',
                            code: 'life_interest_trust',
                        })
                    if (value.other_advice)
                        this.form.selected_products.push({
                            label: 'Other Advice',
                            code: 'other_advice',
                        })
                } else {
                    this.clearForm()
                }
            },
        },
    },
    computed: {
        show: {
            get() {
                return this.showData
            },
            set(value) {
                this.clearForm()
                this.clearErrors()
                this.$emit('close')
            },
        },
        title() {
            return this.form.id ? 'Edit' : 'Create'
        },
        buttonText() {
            return this.form.id ? 'Update' : 'Save'
        },
        validForm() {
            return !!(
                this.form.id &&
                this.form.client_name &&
                (this.form.client_phone !== null ||
                    this.form.client_email !== null)
            )
        },
    },
    data() {
        return {
            form: {
                id: null,
                client_name: null,
                client_phone: null,
                client_email: null,
                client_notes: null,
                selected_products: [],
            },
            errors: {
                client_name: null,
                client_phone: null,
                client_email: null,
                client_notes: null,
            },
            productOptions: [
                { label: 'Complex Will', code: 'complex_will' },
                {
                    label: 'Lasting Power of Attorney',
                    code: 'power_of_attorney',
                },
                {
                    label: 'Protective Property Trust',
                    code: 'protective_property_trust',
                },
                { label: 'Home Protection Trust', code: 'protect_home' },
                { label: 'Right of Occupation', code: 'right_of_occupation' },
                { label: 'Life Interest Trust', code: 'life_interest_trust' },
                { label: 'Other Advice', code: 'other_advice' },
            ],
        }
    },
    methods: {
        submit() {
            if (!this.validForm) return
            this.patch()
        },
        clearForm() {
            this.form.id = null
            this.form.client_notes = null
            this.form.client_name = null
            this.form.client_phone = null
            this.form.client_email = null
            this.form.selected_products = []
        },
        handleErrors(errors) {
            if (errors.client_notes)
                this.errors.client_notes = errors.client_notes[0]
            if (errors.client_name)
                this.errors.client_name = errors.client_name[0]
            if (errors.client_phone)
                this.errors.client_phone = errors.client_phone[0]
            if (errors.client_email)
                this.errors.client_email = errors.client_email[0]
        },
        clearErrors() {
            this.errors.client_email = null
            this.errors.client_phone = null
            this.errors.client_name = null
            this.errors.client_notes = null
        },
        patch() {
            this.clearErrors()
            this.$vs.loading({
                color: 'primary',
                container: '#loading-container_referral_modal',
                scale: 0.45,
            })

            http.patch('legal_referral', this.form)
                .then((response) => {
                    this.$vs.loading.close(
                        '#loading-container_referral_modal > .con-vs-loading'
                    )
                    console.log(response.data)
                    this.$emit('update')
                    this.$emit('close')
                    this.clearForm()
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Code Updated',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-edit',
                        position: 'top-center',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.handleErrors(error.response.data)
                    this.$vs.loading.close(
                        '#loading-container_referral_modal > .con-vs-loading'
                    )
                })
        },
    },
}
</script>
