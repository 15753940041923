<template>
    <vx-card class="vs-con-loading__container" id="loading-container_referral">
        <div
            slot="no-body"
            :style="{
                background: `rgba(var(--vs-secondary),.70)`,
            }"
        >
            <div class="vx-row no-gutter justify-center p-12">
                <div class="vx-col w-full">
                    <div class="text-center">
                        <h1 class="text-center font-bold text-white">
                            <feather-icon
                                :icon="'PaperclipIcon'"
                                class="p-3 inline-flex rounded-full"
                                :class="[`text-white`, { 'mb-4': true }]"
                                :style="{
                                    background: `rgba(var(--vs-secondary),.4)`,
                                }"
                            ></feather-icon>

                            Legal Referral
                        </h1>
                    </div>
                </div>
            </div>
            <div class="vx-row no-gutter w-full">
                <div class="vx-col w-full d-theme-dark-bg p-12">
                    <form @keyup.enter="send">
                        <div class="vx-row">
                            <div class="vx-col w-full mb-3">
                                <vs-input
                                    name="name"
                                    icon-no-border
                                    icon="icon icon-user"
                                    icon-pack="feather"
                                    label="Name"
                                    v-model="form.client_name"
                                    :size="size"
                                    class="w-full"
                                    :danger="errors.client_name !== null"
                                    :danger-text="errors.client_name"
                                />
                            </div>
                            <div class="vx-col w-full mb-3">
                                <label class="vs-input--label">Products</label>
                                <v-select
                                    multiple
                                    taggable
                                    v-model="form.selected_products"
                                    :options="productOptions"
                                    placeholder="Select product(s)"
                                    class="style-chooser"
                                ></v-select>
                            </div>

                            <div class="vx-col w-full lg:w-1/2 mb-3">
                                <vs-input
                                    name="phone"
                                    icon-no-border
                                    icon="icon icon-user"
                                    icon-pack="feather"
                                    label="Phone"
                                    v-model="form.client_phone"
                                    :size="size"
                                    class="w-full"
                                    :danger="errors.client_phone !== null"
                                    :danger-text="errors.client_phone"
                                />
                            </div>
                            <div class="vx-col w-full lg:w-1/2 mb-base">
                                <vs-input
                                    name="email"
                                    icon-no-border
                                    icon="icon icon-user"
                                    icon-pack="feather"
                                    label="Email"
                                    v-model="form.client_email"
                                    :size="size"
                                    class="w-full"
                                    :danger="errors.client_email !== null"
                                    :danger-text="errors.client_email"
                                />
                            </div>

                            <div class="vx-col w-full mb-3">
                                <vs-textarea
                                    name="notes"
                                    icon-no-border
                                    icon="icon icon-user"
                                    icon-pack="feather"
                                    label="Notes"
                                    v-model="form.client_notes"
                                    :size="size"
                                    class="w-full"
                                    :danger="errors.client_notes !== null"
                                    :danger-text="errors.client_notes"
                                />
                            </div>
                        </div>
                        <div class="flex flex-row-reverse">
                            <vs-button
                                color="secondary"
                                class="w-full xl:w-auto mt-5"
                                :disabled="!validForm"
                                :size="size"
                                @click="send"
                                >Send Referral</vs-button
                            >
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { http } from '@/services'

export default {
    name: 'ReferCode',
    components: {
        vSelect,
    },
    computed: {
        size() {
            return this.$store.getters.windowBreakPoint === 'xs'
                ? 'large'
                : null
        },
        validForm() {
            return !!(
                this.form.client_name &&
                (this.form.client_email || this.form.client_phone) &&
                (this.form.selected_products || this.form.client_notes)
            )
        },
    },
    data() {
        return {
            form: {
                client_name: null,
                client_phone: null,
                client_email: null,
                client_notes: null,
                selected_products: null,
            },
            errors: {
                client_name: null,
                client_phone: null,
                client_email: null,
                client_notes: null,
            },
            productOptions: [
                { label: 'Complex Will', code: 'complex_will' },
                {
                    label: 'Lasting Power of Attorney',
                    code: 'power_of_attorney',
                },
                {
                    label: 'Protective Property Trust',
                    code: 'protective_property_trust',
                },
                { label: 'Home Protection Trust', code: 'protect_home' },
                { label: 'Right of Occupation', code: 'right_of_occupation' },
                { label: 'Life Interest Trust', code: 'life_interest_trust' },
                { label: 'Other Advice', code: 'other_advice' },
            ],
        }
    },
    methods: {
        send() {
            this.clearErrors()
            this.$vs.loading({
                color: 'secondary',
                container: '#loading-container_referral',
                scale: 0.45,
            })

            http.post('legal_referral', this.form)
                .then((response) => {
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Referral sent',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-send',
                        position: 'top-center',
                    })
                    this.$vs.loading.close(
                        '#loading-container_referral > .con-vs-loading'
                    )
                    this.clearForm()
                    this.$emit('update')
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.loading.close(
                        '#loading-container_referral > .con-vs-loading'
                    )
                    this.handleErrors(error.response.data)
                })
        },
        clearErrors() {
            this.errors.client_notes = null
            this.errors.client_name = null
            this.errors.client_email = null
            this.errors.client_phone = null
        },
        clearForm() {
            this.form.client_notes = null
            this.form.client_email = null
            this.form.client_name = null
            this.form.client_phone = null
            this.form.selected_products = null
        },
        handleErrors(errors) {
            if (errors.client_phone)
                this.errors.client_phone = errors.client_phone[0]
            if (errors.client_email)
                this.errors.client_email = errors.client_email[0]
            if (errors.client_name)
                this.errors.client_name = errors.client_name[0]
            if (errors.client_notes)
                this.errors.client_notes = errors.client_notes[0]
        },
    },
}
</script>

<style lang="scss">
.vs__selected {
    background-color: #345a7a !important;
    border: none;
    padding: 5px;
}

.style-chooser .vs__dropdown-option--highlight {
    background-color: grey;
}
.v-select .vs__selected {
    color: white !important;
}
</style>
