<template>
    <vs-table
        :onlyClickCheckbox="true"
        ref="table"
        v-model="selected"
        :sst="true"
        :data="logs"
    >
        <div slot="header" class="flex items-center flex-grow justify-end">
            <div>
                <vs-input icon="search" placeholder="Search" v-model="search" />
            </div>
        </div>

        <template slot="thead">
            <vs-th>Date</vs-th>
            <vs-th>Request</vs-th>
            <vs-th>Client</vs-th>
            <vs-th>Notes</vs-th>
            <vs-th>Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
            <tbody>
                <vs-tr :data="log" :key="index" v-for="(log, index) in data">
                    <vs-td>
                        {{ formatDateAndTime(log.date) }}
                    </vs-td>

                    <vs-td>
                        <vs-chip v-if="log.protect_home"
                            >Home Protection</vs-chip
                        >
                        <vs-chip v-if="log.right_of_occupation"
                            >Right of Occupation</vs-chip
                        >
                        <vs-chip v-if="log.protective_property_trust"
                            >Protective Property Trust</vs-chip
                        >
                        <vs-chip v-if="log.complex_will">Complex Will</vs-chip>

                        <vs-chip v-if="log.life_interest_trust"
                            >Life Interest Trust</vs-chip
                        >
                        <vs-chip v-if="log.power_of_attorney"
                            >Lasting Power of Attorney</vs-chip
                        >
                        <vs-chip v-if="log.other_advice">Other Advice</vs-chip>
                    </vs-td>
                    <vs-td
                        >{{ log.client_name }}
                        <small>{{ log.client_email }}<br /></small>
                        <small>{{ log.client_phone }}</small>
                    </vs-td>

                    <vs-td>{{ log.client_notes }}</vs-td>

                    <vs-td>
                        <div class="flex justify-end row-hidden-elements">
                            <vs-button
                                type="flat"
                                icon-pack="feather"
                                icon="icon-edit"
                                @click="$emit('edit', log)"
                            ></vs-button>
                            <vs-button
                                color="secondary"
                                type="flat"
                                icon-pack="feather"
                                icon="icon-trash"
                                @click="$emit('deleteSingle', log)"
                            ></vs-button>
                        </div>
                    </vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'ReferralTable',
    mixins: [textFormat],
    props: ['data', 'itemsPerPage', 'view'],
    computed: {
        logs() {
            return this.data.data
        },
        search: {
            set(value) {
                if (value.length > 1 || value === '') {
                    this.searchQuery = value
                    this.$emit('search', value)
                }
            },
            get() {
                return this.searchQuery
            },
        },
    },
    data() {
        return {
            selected: [],
            searchQuery: '',
            status: 'All',
            selectMultiple: false,
        }
    },
    methods: {
        toggleSelect() {
            this.selected = []
            this.selectMultiple = !this.selectMultiple
        },
        setFilter(status, value) {
            this.status = status
            this.$emit('filter', value)
        },
        progressStatus(log) {
            if (log.client.code_completed) return 'Will Created'
            if (log.client.code_used) return 'Account Created'
            if (log.client.code_clicked) return 'Code Clicked'
            if (log.client.code_sent) return 'Code Sent'
        },
    },
}
</script>

<style>
.row-hidden-elements {
    visibility: hidden;
}
.vs-table tr:hover .row-hidden-elements {
    visibility: visible;
}
</style>
